import React from "react";
import styled, { keyframes } from "styled-components";
import { Location } from "@gatsbyjs/reach-router";
import { getName } from "../../helpers/utils";
import { replaceFieldName } from "../../helpers/utils";
import { Tabs, Content } from "../../components/Section/RightPart";
import CopyCode from "../../components/Section/CopyCode";
import {
  Wrapper as ResponseWrapper,
  Container as ResponseContainer
} from "../../components/Section/Responses";



const ErrorsPreview = ({ errorData, location }) => {

  const sort = {
    title: errorData?.title || "",
    message: replaceFieldName(errorData?.message, errorData?.fieldName) || "",
    detail: errorData?.detail || "",
    type:
      errorData?.type.replace(
        "{applicationNamePathUrl}",
        getName(location.pathname.split("/")[1])
      ) || "",
    status: errorData?.status || "",
    errorCode: errorData?.errorCode || ""
  };

  return (
    <Wrapper>
      <Tabs>
        <Content>
          <ResponseWrapper>
            <ResponseContainer>
              <CopyCode label="JSON Errors">
                {JSON.stringify(sort, null, 2)}
              </CopyCode>
            </ResponseContainer>
          </ResponseWrapper>
        </Content>
      </Tabs>
    </Wrapper>
  );
};

const fade = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const Wrapper = styled.div`
  position: sticky;
  top: 150px;
  right: 0px;
  height: fit-content;
  padding-bottom: 20px;
  width: 850px;
  min-width: 400px;
  margin: 30px 30px 50px 20px;
  border-radius: 8px;
  animation: ${fade} 1s;
  display: flex;
  flex-direction: column;
  & > *:last-child {
    margin: -10px;
  }
`;

//eslint-disable-next-line
export default (props) => (
  <Location>
    {(locationProps) => <ErrorsPreview {...locationProps} {...props} />}
  </Location>
);