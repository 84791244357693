import React, { useState, useContext, useEffect } from "react";
import ErrorsPreview from "../ErrorsPreview";
import {
  getErrorTextStatus,
  sortErrorCode,
  replaceFieldName,
} from "../../helpers/utils";
import styled from "styled-components";
import { Context } from "../Layout/index";

const ErrorsTable = ({ data, target, setTarget, title }) => {
  const errors = sortErrorCode(data);
  const { width } = useContext(Context) || {};

  const [targetId, setTargetId] = useState();

  const selectedError =
    errors && errors.find((item) => item.errorCode === targetId);

  const formatName = (err) => {
    if (!err) return;
    err.title = getErrorTextStatus(parseInt(err.status), true)
      .replace(/[()]|[0-9]/g, "")
      .trimStart();
    delete err.name;
    return err;
  };

  useEffect(() => {
    setTargetId(target ? target : errors[0].errorCode);
    // eslint-disable-next-line
  }, [target]);

  const filteredArr =
    errors &&
    errors.reduce((acc, current) => {
      const x = acc.find((item) => item.errorCode === current.errorCode);
      if (!x) {
        return acc.concat([current]);
      } else {
        return acc;
      }
    }, []);

  return (
    <Wrapper>
      <Container>
        <Title>{title}</Title>
        <Head>
          <div>Error Code</div>
          <div>Status</div>
          <div>Message </div>
          <div>Detail</div>
        </Head>
        {filteredArr &&
          filteredArr.map((error) => (
            <Raw
              id={error.errorCode}
              key={error.errorCode}
              target={
                target === error.errorCode || targetId === error.errorCode
                  ? "true"
                  : "false"
              }
              onClick={(e) => {
                setTargetId(e.currentTarget.getAttribute("id"));
                setTarget("");
              }}
            >
              <Field>{error.errorCode || "N/A"}</Field>
              <Field>{getErrorTextStatus(error.status, false) || "N/A"}</Field>
              <Field>
                {replaceFieldName(error.message, error.fieldName) || "N/A"}
              </Field>
              <Field>{error.detail || "N/A"}</Field>
            </Raw>
          ))}
      </Container>
      {width > 900 && <ErrorsPreview errorData={formatName(selectedError)} />}
    </Wrapper>
  );
};

const Title = styled.h2`
  text-align: start;
  max-width: 850px;
  width: 100%;
  margin-bottom: 50px;
  line-height: 40px;
  font-size: 25px;
  font-weight: 500;
  color: #52b474;
  margin-top: 10px;
  color: ${({ theme }) => theme.text};
`;

const Container = styled.div`
  max-width: 850px;
  width: 100%;
  min-width: 310px;
`;

const Raw = styled.div`
  display: flex;
  min-width: 60px;
  & > div {
    min-height: 80px;
    max-width: 187.5px;
    width: 100%;
    padding: 0 12px;
    font-size: 14px;
    @media (max-width: 555px) {
      font-size: 12px;
    }
  }

  width: 100%;

  background-color: ${({ target }) => target === "true" && "#52b4743d"};
  border-radius: 5px;
  cursor: pointer;
  &:hover {
    background-color: #52b4741d;
    transition: 0.3s;
  }
`;

const Field = styled.div`
  padding: 0 12px;
  word-break: break-word;
  border-top: 1px solid rgba(68, 68, 68, 0.365);
  max-width: 187.5px;
  line-height: 26px;
  font-size: 14px;
  @media (max-width: 555px) {
    max-width: 120px;
    font-size: 10px;
  }
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  @media (max-width: 1100px) {
    margin: 0;
  }
  padding-top: 70px;
  @media (min-width: 2000px) {
  justify-content: start;
  }
`;

const Head = styled.div`
  display: flex;
  min-width: 60px;
  border-bottom: 1px solid rgba(68, 68, 68, 0.365);
  & > div {
    height: 50px;
    max-width: 187.5px;
    width: 100%;
    padding: 0 12px;
    font-weight: bold;
    font-size: 14px;
    @media (max-width: 555px) {
      font-size: 12px;
    }
  }

  width: 100%;
`;

export default ErrorsTable;
