import React, { useEffect, useState } from "react";

import { useStaticQuery, graphql } from "gatsby";
import styled from "styled-components";
import { Location } from "@gatsbyjs/reach-router";
import ErrorsTable from "../components/ErrorsTable";
import { scrollToSection, getName } from "../helpers/utils";
import { Helmet } from "react-helmet";
import Description from "../components/CmsComponents/Description";

const PageTemplateErrors = ({ pageContext, location }) => {
  const data = useStaticQuery(graphql`
    query {
      allErrorsYamlYaml {
        nodes {
          name
          resources {
            fields {
              errorTypes {
                name
                message
                detail
                type
                status
                errorCode
              }
              name
            }
            name
          }
        }
      }
    }
  `);

  const errors = data.allErrorsYamlYaml.nodes.filter(
    (node) => getName(node.name) === getName(location.pathname).split("/")[1]
  );

  const [target, setTarget] = useState();

  useEffect(() => {
    if (location?.state?.data) {
      scrollToSection(location?.state?.data, -300);
      setTarget(location?.state?.data);
    }
    //eslint-disable-next-line
  }, []);

  return (
    <Wrapper>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          {`smsmode© | API REST ${getName(
            location.pathname.split("/")[1]
          ).toUpperCase()}`}
        </title>
      </Helmet>
      <Header>
        <PageTitle id="errors_definitions_title">Errors definitions</PageTitle>
        <Description anchor="errors_definitions_title" />
      </Header>
      <ErrorsTable
        data={errors[0].resources}
        target={target}
        setTarget={setTarget}
        title={`${errors[0].name} errors`}
      />
    </Wrapper>
  );
};

const Header = styled.div`
  padding-left: 250px;
  padding-right: 30px;
  @media (max-width: 1100px) {
    padding: 0;
  }
`;

const PageTitle = styled.h2`
  text-align: start;
  width: 100%;
  margin-bottom: 10px;
  line-height: 40px;
  font-size: 35px;
  font-weight: 500;
  color: #52b474;
  margin-top: 10px;
  color: ${({ theme }) => theme.text};
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  & > h1 {
    width: 850px;
    margin-bottom: 200px;
  }
  padding: 20px;
  
}
`;

//eslint-disable-next-line
export default (props) => (
  <Location>
    {(locationProps) => <PageTemplateErrors {...locationProps} {...props} />}
  </Location>
);
